<template>
  <div class="statistics-numbers"
       :class="{'retail' : isRetail}">
    <p v-if="title">
      {{title}}
    </p>
    <div class="statistics-numbers-wrapper">
      <StatisticsNumber v-for="(num, index) in numbers"
                        :key="index"
                        :value="num"
                        :is-retail="isRetail"
                        :is-drawn-type="isDrawnType"
                        :type="type"/>
    </div>
  </div>
</template>

<script>
import StatisticsNumber from '@/components/StatisticsNumber';

export default {
  name: 'StatisticsNumbers',
  components: { StatisticsNumber },
  props: {
    title: {
      type: String,
    },
    numbers: {
      type: Array,
      required: true,
    },
    type: {
      type: String, // hot or cold
      required: true,
    },
    isDrawnType: {
      type: Boolean,
      default: true,
    },
    isRetail: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
 .statistics-numbers {
   padding: 6px;
   &.retail {
     font-size: 16px;
     @media (min-width: 1920px) {
       font-size: 24px;
       .statistics-numbers-wrapper {
         grid-gap: 8px;
       }
     }
   }
 }
  p {
    color: #A4A4A4;
    text-align: center;
    text-transform: uppercase;
  }
  .statistics-numbers-wrapper {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 5px;
    margin-top: 8px;
  }
</style>
