<template>
  <div class="statistics-wrapper"
           :class="{ 'in-draw': inDraw }">
    <SidebarTitle :title="$store.getters.translate('statistics')"
                  :subtitle="$store.getters.translate('lastnRounds', 30)" />
    <section class="numbers">
      <StatisticsNumbersBox :is-drawn-type="setDrawnType"
                            :in-draw="inDraw"
                            :is-retail="true" />
    </section>
  </div>
</template>

<script>
import StatisticsNumbersBox from '@/components/StatisticsNumbersBox';
import SidebarTitle from '@/components/Retail/SidebarTitle';
import EventBus from '@/store/EventBus';
import switchComponent from '@/utility/switchComponent';

export default {
  name: 'Statistics',
  components: { SidebarTitle, StatisticsNumbersBox },
  data() {
    return {
      emitDrawnType: false,
      storeTimeout: null,
      emitDrawnTypeTimeout: null,
    };
  },
  props: {
    inDraw: Boolean,
    isDrawnType: Boolean,
  },
  computed: {
    setDrawnType() {
      return this.isDrawnType || this.emitDrawnType;
    },
  },
  methods: {
    closeComponent() {
      switchComponent(
        'right',
        2,
        this.$store.getters.transitionBetweenComponentDuration - 1000,
      );
    },
    clearTimeout() {
      clearTimeout(this.storeTimeout);
      clearTimeout(this.emitDrawnTypeTimeout);
    },
  },
  mounted() {
    EventBus.$on('stopIntervalsAndTimeouts', () => {
      this.clearTimeout();
    });
    if (!this.isDrawnType && !this.inDraw) {
      this.storeTimeout = setTimeout(() => {
        EventBus.$emit('changeStatistics');
        this.emitDrawnTypeTimeout = setTimeout(() => {
          this.emitDrawnType = true;
          this.closeComponent();
        }, 1100);
      }, this.$store.getters.transitionBetweenComponentDuration);
    }
  },
  beforeDestroy() {
    this.clearTimeout();
  },
};
</script>

<style lang="scss" scoped>
.statistics-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  &:not(&.in-draw) {
    .numbers {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.in-draw {
    .numbers {
      position: relative;
    }
  }
  .numbers {
    .statistics-numbers-box {
      background-color: transparent;
      box-shadow: none;
    }
  }
}
</style>
