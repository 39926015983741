<template>
  <div class="statistics-numbers-box"
       :class="{'retail' : isRetail, 'draw': inDraw}">
    <StatisticsNumbers :numbers="isDrawnType
                       ? $store.getters.mostDrawnNumbers
                       : $store.getters.mostPlayedNumbers"
                       :title="$store.getters.translate(isDrawnType ? 'mostDrawn' : 'mostPlayed')"
                       :is-drawn-type="isDrawnType"
                       :isRetail="isRetail"
                       type="hot"/>
    <StatisticsNumbers :numbers="isDrawnType
                       ? $store.getters.leastDrawnNumbers
                       : $store.getters.leastPlayedNumbers"
                       :title="$store.getters.translate(isDrawnType ? 'leastDrawn' : 'leastPlayed')"
                       :is-drawn-type="isDrawnType"
                       :isRetail="isRetail"
                       type="cold"/>
  </div>
</template>

<script>
import StatisticsNumbers from '@/components/StatisticsNumbers';

export default {
  name: 'StatisticsNumbersBox',
  components: { StatisticsNumbers },
  props: {
    isDrawnType: {
      type: Boolean,
      default: true,
    },
    isRetail: {
      type: Boolean,
      default: false,
    },
    inDraw: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  .statistics-numbers-box {
    display: flex;
    background-color: rgba(45, 45, 45, 0.8);
    border-radius: 8px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
    flex-direction: column;
    justify-content: space-between;
    &:not(&.retail) {
      height: 234px;
      width: 191px;
    }
    &.draw {
      > .statistics-numbers {
        @media (min-width: 1920px) {
          margin-top: 24px;
        }
      }
    }
  }
</style>
