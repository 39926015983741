<template>
  <div class="statistics-number"
       :class="[
         type,
         {
           'retail' : isRetail,
           'played' : !isDrawnType,
         }]">
    {{value}}
  </div>
</template>

<script>
export default {
  name: 'StatisticsNumber',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String, // hot or cold
      required: true,
    },
    isDrawnType: {
      type: Boolean,
      default: true,
    },
    isRetail: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .statistics-number {
    align-items: center;
    border: 2px solid;
    border-radius: 4px;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 36px;
    justify-content: center;
    width: 40px;
    &.cold {
      background-color: rgba(10, 88, 164, 0.5);
      border-color: #0A58A4;
      &.played {
        background: rgba(127, 86, 186, 0.5);
        border-color: #7F56BA;
      }
    }
    &.hot {
      background-color: rgba(255, 35, 35, 0.5);
      border-color: #FF2323;
      &.played {
        background: rgba(218, 255, 0, 0.5);
        border-color: #DAFF00;
      }
    }
    &.retail {
      border-radius: 8px;
      font-size: 18px;
      height: 50px;
      width: 50px;
      @media (min-width: 1920px) {
        font-size: 24px;
        height: 74px;
        width: 74px;
      }
    }
  }
</style>
