import { render, staticRenderFns } from "./StatisticsNumbersBox.vue?vue&type=template&id=2adf2dfe&scoped=true&"
import script from "./StatisticsNumbersBox.vue?vue&type=script&lang=js&"
export * from "./StatisticsNumbersBox.vue?vue&type=script&lang=js&"
import style0 from "./StatisticsNumbersBox.vue?vue&type=style&index=0&id=2adf2dfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adf2dfe",
  null
  
)

export default component.exports